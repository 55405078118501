import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Sider from '@/components/global/sider/index.vue';
import Perm from '@/router/permission';
import PermCode from '@/router/perm-code';
const flowManage = PermCode.flowManage;
let FlowManage = class FlowManage extends Vue {
    constructor() {
        super(...arguments);
        this.menus = [
            {
                name: '流程干预',
                code: flowManage.workflowInstance,
                routeName: 'flow-instance',
                icon: '',
                children: [],
            },
            {
                name: '流程委托',
                code: flowManage.workflowAgency,
                routeName: 'flow-entrust',
                icon: '',
                children: [],
            },
            {
                name: '流程异常日志',
                code: flowManage.workException,
                routeName: 'flow-exception',
                icon: '',
                children: [],
            },
        ];
    }
    get siderMenus() {
        return Perm.filterMenus(this.menus);
    }
};
FlowManage = __decorate([
    Component({
        name: 'FlowManage',
        components: {
            Sider,
        },
    })
], FlowManage);
export default FlowManage;
